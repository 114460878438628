/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Flex from "components/core/Grid/Flex";
import ReactHtmlParser from "react-html-parser";
import { manageClasses } from "helpers/sharedHelpers";
import { v1 as uuidv1 } from "uuid";

import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import { ReactComponent as DownloadIcon } from "assets/img/icons/downloadIcon.svg";
import { ReactComponent as FilePdf } from "assets/img/icons/filePdf.svg";
import { ReactComponent as FileJpeg } from "assets/img/icons/fileJpeg.svg";
import { ReactComponent as FileDoc } from "assets/img/icons/fileDoc.svg";
import { ReactComponent as FilePpt } from "assets/img/icons/filePpt.svg";
import { ReactComponent as FilePrezi } from "assets/img/icons/filePrezi.svg";
import { ReactComponent as FileXls } from "assets/img/icons/fileXls.svg";

import * as S from "./ContentModuleStyle";
import {
  ResponsiveCourseContainer,
  CourseInfo,
  CourseModule,
  CourseModuleFlex,
  CourseModuleTitle,
  CourseTasksList,
  CourseTask,
  CourseTaskLink,
  CourseModulesList,
  ModuleTitle,
} from "../../../Sidebar/CourseProgress/CourseProgressStyle";
import {
  CourseProgress as CourseStyle,
  CourseProgressPointer,
  CourseProgressCount,
} from "../../../Sidebar/SidebarAcademyStyle";
// eslint-disable-next-line import/named
import { Video } from "../../../../../components/core/Video";
import { useVideoContext } from "../../../../../context/VideoContexts";

const ContentModule = ({ contents, answer, isCorrect, titleSubmodule }) => {
  const { setIsVideo, setVideoIsFinished } = useVideoContext();

  const [selectOption, setSelectOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 768);
  };
  const startCourseProgress = 138;

  const handleOnChangeInputQuizOption = (id) => {
    setSelectOption(id);
    setCheckedOption(id);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  const { course_details: course, doneSubmodules } = useSelector(
    ({ academy }) => academy,
  );

  const {
    title,
    module_courses: courseModules,
    user_status: userStatus,
  } = course;

  useEffect(() => {
    if (selectOption !== null) {
      answer(selectOption);
      setSelectOption(null);
    }
  }, [selectOption, answer]);

  function returnIconFromFormatFile(format) {
    const formatsXls = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.oasis.opendocument.spreadsheet",
      ".xls",
      ".xlsm",
      ".csv",
      ".xml",
      "text/csv",
      "application/vnd.ms-excel.sheet.macroenabled.12",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "unknow",
    ];
    const formatsDoc = [
      ".txt",
      ".odt",
      "application/vnd.oasis.opendocument.text",
      "text/plain",
      ".rtf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".docx",
      ".doc",
      "application/msword",
      "text/html",
      "application/rtf",
    ];
    const formatsPpt = [
      ".ppt",
      ".pptx",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-powerpoint",
      "application/vnd.oasis.opendocument.presentation",
      ".form",
    ];
    const formatsJpg = ["image/png", "image/jpeg", "image/gif", ".jpg"];
    const formatsPrezi = [".prezi"];
    if (formatsXls.includes(format)) {
      return <FileXls />;
    }
    if (formatsDoc.includes(format)) {
      return <FileDoc />;
    }
    if (formatsPpt.includes(format)) {
      return <FilePpt />;
    }
    if (formatsJpg.includes(format)) {
      return <FileJpeg />;
    }
    if (formatsPrezi.includes(format)) {
      return <FilePrezi />;
    }
    return <FilePdf />;
    //   case 'application/pdf':
  }

  const renderContent = (
    kind,
    {
      answers,
      id: idContent,
      image,
      text_content: textcontent,
      video,
      linkcontent,
      title,
      content_files: contentFiles,
    },
  ) => {
    const options = answers;
    const answered = options?.find((option) => option.chosen);

    switch (kind) {
      case "image": {
        setIsVideo(false);
        return <S.ImageKind src={image.url} alt="" />;
      }
      case "quiz": {
        setIsVideo(false);
        return (
          <Flex flexWrap="wrap" spaceChildren={2} justifyContent="center">
            {options?.map(({ answer_text, id, is_correct }) => (
              <S.QuizzContainer
                selected={answered?.id === id ? is_correct : undefined}
                key={uuidv1()}
              >
                <S.QuizzOptions
                  id={id}
                  value={id}
                  type="radio"
                  verify={isCorrect}
                  name={`quiz-${idContent}`}
                  disabled={isCorrect !== null || !!answered}
                  onChange={() => handleOnChangeInputQuizOption(id)}
                  checked={checkedOption === id}
                />
                <span>{answer_text}</span>
              </S.QuizzContainer>
            ))}
          </Flex>
        );
      }
      case "text": {
        setIsVideo(false);
        return <S.TextKind>{ReactHtmlParser(textcontent)}</S.TextKind>;
      }
      case "video": {
        setIsVideo(true);
        return (
          <Video
            autoPlay={false}
            width="100%"
            height="284px"
            preload
            muted={false}
            controls
            controlsList="nodownload"
            url={video.url}
          />
        );
      }
      case "embeded_video_link": {
        setIsVideo(true);
        return (
          <div
            style={{
              width: "100%",
              height: "288px",
              overflow: "hidden",
            }}
          >
            <ReactPlayer
              url={linkcontent}
              width="100%"
              height="100%"
              controls
              onEnded={() => setVideoIsFinished(true)}
            />
          </div>
        );
      }
      case "link": {
        setIsVideo(false);
        return (
          <S.Link
            key={idContent}
            href={`${linkcontent}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{title}</p>
            <ArrowRight />
          </S.Link>
        );
      }
      case "file": {
        setIsVideo(false);
        return (
          <>
            {contentFiles.length > 0 && (
              <>
                {contentFiles.map(({ id, file, name_file, format_file }) => (
                  <S.ItemFolder key={id} spaceChildren={4} p={2}>
                    <S.LinkFile
                      href={`${file.url ? file.url : ""}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {returnIconFromFormatFile(format_file)}
                    </S.LinkFile>
                    <S.NameFile>
                      <S.LinkFile
                        href={`${file.url ? file.url : ""}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <S.SubTitle>{name_file}</S.SubTitle>
                      </S.LinkFile>
                    </S.NameFile>
                    <S.LinkFile
                      href={`${file.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={name_file}
                    >
                      <DownloadIcon />
                    </S.LinkFile>
                  </S.ItemFolder>
                ))}
              </>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  const toggleCollapse = (target, index) => {
    const { height } = target.querySelector("ul").getBoundingClientRect();
    const svg = target.querySelector("svg");
    target.classList.toggle("uncollapsed");
    const hr = document.getElementById(`hr-${index}`);

    if (target.classList.contains("uncollapsed")) {
      target.style.height = "auto";
      svg.style.transform = "rotate(90deg)";
      if (hr) {
        hr.style.margin = `-${height}px 0 0 20px`;
        hr.style.height = `${height}px`;
      }
    } else {
      target.style.height = "48px";
      svg.style.transform = "rotate(-90deg)";
      if (hr) {
        hr.style.margin = "0 0 -6px 20px";
        hr.style.height = `16px`;
      }
    }
  };

  const renderModule = (
    { id: moduleId, name, was_finished: completed, submodules },
    index,
  ) => {
    const inProgress =
      (!completed && !!submodules.find(({ was_seen }) => was_seen)) ||
      !!submodules.find(({ id }) => doneSubmodules.find((done) => done === id));

    return (
      <>
        <CourseModule
          key={moduleId}
          variant={manageClasses({ completed, inProgress })}
          onClick={({ currentTarget }) => toggleCollapse(currentTarget, index)}
        >
          <CourseModuleFlex variant={manageClasses({ completed, inProgress })}>
            <span>{index + 1}</span>
            <CourseModuleTitle>{name}</CourseModuleTitle>
            <ArrowLeftIcon />
          </CourseModuleFlex>
          <CourseTasksList>
            {submodules.map(
              ({ id, name: taskTitle, was_seen: completedSub }, taskIndex) => {
                const completedDone = !!doneSubmodules.find(
                  (done) => done === id,
                );
                const completedS = completedDone || completedSub;

                const inSubProgress =
                  taskIndex !== 0 &&
                  !completedSub &&
                  submodules[taskIndex - 1].was_seen;
                const inProgressDone =
                  taskIndex !== 0 &&
                  !completedDone &&
                  !!doneSubmodules.find(
                    (done) => done === submodules[taskIndex - 1].id,
                  );
                const inSProgress = inProgressDone || inSubProgress;

                return (
                  <CourseTask
                    key={id}
                    variant={manageClasses({ completedS, inSProgress })}
                  >
                    <CourseTaskLink
                      completed={completed}
                      onClick={() => completed}
                    >
                      <span>
                        {index + 1}.{taskIndex + 1}
                      </span>
                      {` ${taskTitle}`}
                    </CourseTaskLink>
                    {submodules.length !== taskIndex + 1 && <hr />}
                  </CourseTask>
                );
              },
            )}
          </CourseTasksList>
        </CourseModule>
        {courseModules.length !== index + 1 && <hr id={`hr-${index}`} />}
      </>
    );
  };

  return (
    <>
      {!isDesktop && (
        <>
          <ResponsiveCourseContainer>
            <CourseInfo
              spaceChildren={2}
              completed={userStatus?.status === "finished"}
            >
              <CourseStyle>
                <CourseProgressPointer
                  progress={
                    startCourseProgress -
                    (startCourseProgress * userStatus?.progress_rate) / 100
                  }
                >
                  <circle cx={22} cy={22} r={22} />
                  <circle cx={22} cy={22} r={22} />
                </CourseProgressPointer>
                <CourseProgressCount>
                  {`${parseInt(userStatus?.progress_rate, 10)}%`}
                </CourseProgressCount>
              </CourseStyle>
            </CourseInfo>
            <ModuleTitle>{title}</ModuleTitle>
          </ResponsiveCourseContainer>
          <CourseModulesList>
            {courseModules?.map((courseModule, moduleIndex) =>
              renderModule(courseModule, moduleIndex),
            )}
          </CourseModulesList>
        </>
      )}
      <S.TitleContent>
        {contents && contents[0]?.question_text
          ? contents[0].question_text
          : titleSubmodule}
      </S.TitleContent>
      <S.Content>
        {contents?.map((content) => (
          <div>{renderContent(content.kind_content, content)}</div>
        ))}
      </S.Content>
    </>
  );
};

ContentModule.defaultProps = {
  content: PropTypes.array,
  answer: PropTypes.func,
  isCorrect: PropTypes.bool,
};

export default ContentModule;
